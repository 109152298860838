<template>
  <a
    :href="to"
    class="rounded-md relative inline-flex group items-center justify-center px-6 pb-1.5 pt-2 cursor-pointer font-semibold border-b-4 border-l-2 active:shadow-none shadow-lg text-white overflow-clip"
    :class="classes"
    target="_blank"
  >
    <span
      class="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"
    ></span>
    <span class="relative">
      <slot></slot>
    </span>
  </a>
</template>
<script lang="ts" setup>
export type ButtonProps = {
  variant?: 'primary' | 'secondary'
  to: string
}

const props = defineProps<ButtonProps>()

const classes = computed(() => {
  if (props.variant === 'secondary') {
    return 'active:border-[#BBBBBB] bg-gradient-to-tr from-[#909090] to-[#BBBBBB] border-[#909090]'
  }

  return 'active:border-[#FF5900] bg-gradient-to-tr from-[#e05206] to-[#FF5900] border-[#e05206]'
})
</script>
