<template>
  <blockquote
    class="flex h-full flex-col justify-between bg-white pb-6 pt-2 border-b-2 sm:pb-8 sm:pt-4 lg:pb-12 lg:pt-8"
  >
    <div>
      <div class="flex justify-between items-center">
        <div class="flex gap-0.5 text-yellow-400">
          <svg
            v-for="num in stars"
            :key="num"
            class="h-8 w-8"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
            />
          </svg>
        </div>
        <div class="text-gray-600 uppercase">{{ date }}</div>
      </div>

      <div class="mt-4">
        <p v-if="title" class="text-2xl font-bold text-gray-800 sm:text-3xl">
          {{ title }}
        </p>

        <p class="mt-4 leading-relaxed text-gray-700">
          {{ content }}
        </p>
      </div>
    </div>

    <footer class="mt-4 text-sm font-medium text-gray-700 sm:mt-6">
      <div>&mdash; {{ name }}</div>
      <div v-if="book && bookLink" class="mt-2 font-semibold text-[#B54103]">
        <NuxtLink :to="bookLink">
          {{ book }}
        </NuxtLink>
      </div>
    </footer>
  </blockquote>
</template>
<script setup lang="ts">
export type ReviewModel = {
  stars: number
  title: string
  book?: string
  bookLink?: string
  name: string
  date: string
  content: string
}

export type ReviewProps = {
  model: ReviewModel
}

const props = defineProps<ReviewProps>()
const { stars, name, date, book, bookLink, content, title } = toRefs(
  props.model
)
</script>
